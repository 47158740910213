/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {formatPrice} from 'Util/Price';
import '../plugin/PaymentSystem.style.scss';

const payNow = function (e) {

    e.stopPropagation();
    console.log(e.target.getAttribute('payment_link'));
    window.open(e.target.getAttribute('payment_link'), '_blank');

}

const _getOrderItemsFields = (args, callback) => {
    return [
        ...callback(...args),
        'payment_link',
        'payment_method',
        'order_items'
    ]
};

const render = (args, callback, instance) => {

    // console.clear();
    // console.log(args);
    // console.log(instance.props);
    const {
        order: {
            created_at,
            status,
            increment_id,
            payment_link,
            payment_method,
            total: {
                grand_total: {
                    value,
                    currency
                } = {}
            } = {}
        },
        onViewClick
    } = instance.props;

    return (
        <tr onClick={onViewClick} block="MyAccountOrderTableRow">
            <td>{increment_id ? `#${increment_id}` : ''}</td>
            <td>{created_at}</td>
            <td>
                {status}&nbsp;
                {(payment_link) ? <span payment_link={payment_link} payment_method={payment_method} className='pay-now Button Button_isHollow'
                                        onClick={payNow.bind(this)}>Pay Now</span> : null}
            </td>
            <td block="hidden-mobile">
                {value ? formatPrice(value, currency) : ''}
            </td>
        </tr>
    );

};

export default {
    'Query/Order/Query': {
        'member-function': {
            _getOrderItemsFields
        }
    },
    'Component/MyAccountOrderTableRow/Component': {
        'member-function': {
            // render
        }
    }
};
